import GATSBY_COMPILED_MDX from "/Users/touffi/dev/portfolio/data/pages/static/about.fr.md";
import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import HtmlHeaders from '../../ui/HtmlHeaders';
import Layout from '../../ui/layout/main/containers/Layout';
import StaticPage from '../components/StaticPage';
const StaticPagePage = ({data, location, pageContext, children}) => {
  const {mdx} = data;
  const {allAlternateLang, lang} = pageContext;
  const {frontmatter} = mdx;
  return React.createElement(Layout, {
    mainNavActive: frontmatter.mainNavActive,
    allAlternateLang: allAlternateLang,
    lang: lang
  }, React.createElement(HtmlHeaders, {
    title: frontmatter.title,
    metaDescription: frontmatter.metaDescription,
    path: location.pathname,
    allAlternateLang: allAlternateLang,
    lang: lang
  }), React.createElement(StaticPage, {
    frontmatter: frontmatter,
    lang: lang
  }, children));
};
StaticPagePage.propTypes = {
  data: PropTypes.object,
  children: PropTypes.element,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};
StaticPagePage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(StaticPagePage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
	query ($pageId: String) {
		mdx(id: { eq: $pageId }) {
			frontmatter {
				slug
				title
				mainNavActive
				metaDescription
			}
		}
	}
`;
